.login_div {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.login_div .login_form {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  width: 600px;
  max-width: 100%;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.login_form>* {
  width: 80%;
  height: 40px;
  border-radius: 10px;
  border: 1px solid #ccc;
  padding: 0px 10px;

}

.login_form button {
  border: none;
  background-color: #0b5ed7;
  color: white;
  cursor: pointer;
  font-size: 20px;
}

.login_form>h4 {
  border: none;
  text-align: center;
  font-size: 20px;
  margin-bottom: 0px;
}