header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  padding: 0 2rem;
  background-color: var(--mainColor);
  color: var(--textColor);
  background: linear-gradient(to right, #8AA4AE, #6997a7);
  z-index: 7874374378;
}

.proftag {
  color: #dcdedc;
}

.logo {
  color: white;
}

.logo img {
  width: 200px !important;
  max-width: 100%;
  height: 80px;
}

nav a {
  margin: 0 1rem;
  color: var(--textColor);
  text-decoration: none;
  color: white;
  text-transform: capitalize;
}

/* nav a:hover {
  color: var(--secondaryColor);
}
 */
header .nav-btn {
  padding: 5px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  color: var(--textColor);
  visibility: hidden;
  opacity: 0;
  font-size: 1.8rem;
}

header div,
nav {
  display: flex;
  align-items: center;
  flex: 1;
}

.logsign {
  display: flex;
  align-items: center;
  gap: 20px;
  flex: 1;
}

.logsign a {
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 10px;
  text-transform: capitalize;
  height: 50px;
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}



@media only screen and (max-width:767px) {
  .proftag {
    color: #57707a !important;
  }

  header .nav-btn {
    visibility: visible;
    opacity: 1;
  }

  header nav {
    position: fixed;
    top: -100vh;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    background-color: var(--mainColor);
    transition: 1s;
    text-transform: capitalize;
    color: #57707a;
  }

  .logsign {
    flex-direction: column;
  }

  .logsign a {
    width: fit-content;
    background-color: #57707a;
    color: #dcdedc !important;
  }

  nav a {
    color: #57707a;
  }

  header .responsive_nav {
    transform: translateY(100vh);
    background-color: #dcdedc;

  }

  header .links {
    display: flex;
    align-items: center;
    gap: 20px;
    flex-direction: column;
  }

  .proftag {
    color: #57707a !important;
    font-size: 20px !important;
  }

  .logsign a,
  .proftag {
    color: #57707a;
    text-transform: capitalize;
  }

  nav .nav-close-btn {
    position: absolute;
    top: 2rem;
    right: 2rem;
  }

  nav a,
  .proftag {
    font-size: 1.5rem !important;
  }
}

:where(.css-dev-only-do-not-override-yp8pcc).ant-dropdown .ant-dropdown-menu,
:where(.css-dev-only-do-not-override-yp8pcc).ant-dropdown-menu-submenu .ant-dropdown-menu {
  padding: 4px;
  list-style-type: none;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 8px;
  outline: none;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  margin-top: 25px;
}

/* header {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #57707a;
}

header .logo img {
  width: 60px;
}

header .links {
  display: flex;
  align-items: center;
  gap: 20px;
}

.links a {
  color: #dedcdc;
}

.logsign {
  display: flex;
  align-items: center;
  gap: 20px;
}

.logsign a {
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 10px;
  text-transform: capitalize;
}

.logsign a:first-of-type {
  background-color: #c5bac4;
  border: none;
  color: white;
}

.logsign a:last-of-type {
  background-color: #c5bac4;
  border: none;
  color: white;
}

header a {
  text-decoration: none;
  color: white;
  text-transform: capitalize;
} */