.contact_div {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 66.5px);
}

.contact {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 20px;
  justify-content: space-between;
  width: 800px;
  max-width: 100%;
  height: 600px;
  max-height: 100%;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.contact>img {
  width: 50%;
  height: 100%;
  max-width: 100%;
}

.contact_form {
  padding: 30px;
  width: 48%;
  height: 100%;
}

.contact_form>h3 {
  margin-bottom: 30px;
  text-align: center;
  text-transform: capitalize;
}

.contact_form>form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.contact_form>form input {
  width: 100%;
  height: 50px;
  margin-bottom: 30px;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #ccc;
  background-color: #ccc;
}

.contact_form>form textarea {
  width: 100%;
  height: 200px;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #ccc;
  margin-bottom: 30px;
  resize: none;
  background-color: #ccc;
  outline: none;
}

.contact_form>form>button {
  width: 200px;
  height: 40px;
  border-radius: 20px;
  border: none;
  background-color: #c5bac4;
  color: white;
}