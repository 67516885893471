.about_div {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 65.4px);
  background-image: url("../../assets/images/WhatsApp\ Image\ 2023-06-03\ at\ 20.22.19.jpg");
  background-size: contain;
  

  padding: 10px;
}

.about {
  padding: 30px;
  width: 800px;
  margin: auto;
  max-width: 100%;
  background-color: white;
  border-radius: 20px;
}

.about h4 {
  text-align: center;
  text-transform: capitalize;
  margin-bottom: 20px;
}

.about>p {
  color: black;
  color: #989daa;
}

.developers {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.developers .developer {
  width: 25%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.developer h2 {
  color: #57707a;
}

.developer img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 10px;
}

.contact_button {
  text-align: center;
  margin-top: 20px;
}

.contact_button>span {
  display: inline-block;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  height: 40px;
  border-radius: 10px;
  cursor: pointer;
  background-color: #989daa;
  text-transform: capitalize;
  color: white;
}