.buildings .building {
  width: 32%;
  display: flex;
  flex-direction: row;
  background-color: #F5F4F8;
  padding: 20px;
  border-radius: 20px;
  gap: 20px;
}

.building_text h3 {
  margin-bottom: 20px;
  font-size: 19px;
}

.building_text .location {
  display: flex;
  align-items: center;
  text-transform: capitalize;
}

.building_text .location>span>svg {
  color: #c5bac4;
}

.building_image {
  position: relative;
}

.building_image img {
  width: 200px;
  max-width: 100%;
  height: 200px;
  border-radius: 20px;
}

.view_det button {
  width: 100%;
  height: 30px;
  border: none;
  background-color: #c5bac4;
  color: white;
  text-transform: capitalize;
  border-radius: 10px;
  margin-top: 10px;
}

/* .building_image .love {
  position: absolute;
  top: 10px;
  right: 20px;
  background-color: #c5bac4;
  color: white;
  width: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  border-radius: 50%;
  z-index: 238923;
}
 */
@media (max-width:992px) {
  .buildings .building {
    width: 32%;
  }
}

@media (max-width:767px) {
  .buildings .building {
    width: 48%;
    flex-direction: column-reverse;
  }

  .building_image {
    width: 100%;
  }

  .building_image img {
    width: 100%;
  }

  .view_det {
    text-align: center;
  }

  .building_text .location {
    justify-content: center;
  }

  .building_text {
    text-align: center;
  }

  .building_text h3 {
    text-align: center;
  }
}

@media (max-width:565px) {
  .buildings .building {
    width: 100%;
    justify-content: space-between;
  }
}