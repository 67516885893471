.buildings{
  padding: 20px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 20px;
  width: 1100px;
  max-width: 100%;
  margin: auto;
}

