.banner {
  position: relative;
  background-image: url("../../../assets/images/WhatsApp\ Image\ 2023-05-16\ at\ 11.02.13\ PM.jpeg");
  height: calc(100vh - 63.4px);
  background-size: cover;
  /* background-position-y: -800px; */
  background-repeat: no-repeat;
  z-index: 33;

}

.banner_content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 22;
}

.banner_content>h3 {
  font-size: 22px;
  text-transform: capitalize;
  letter-spacing: 1.1px;
  color: white;
}

.banner_content p {
  text-transform: capitalize;
  color: white;
  margin-top: 10px;
  letter-spacing: 1.3px;
  font-size: 22px;
}

.banner .overlay {
  background-color: #004a757d;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
}